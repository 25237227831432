@use "./constants.scss";

@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme' with ($fallback: themes.$g100,
  // Override theme
  $theme: ( // Background
    background-brand: constants.$terrestra48,
    // Border
    border-interactive: constants.$terrestra55,
    // Links
    link-primary: constants.$terrestra55,
    link-primary-hover: constants.$terrestra75,
    link-secondary: constants.$terrestra75,
    link-inverse: constants.$terrestra48,
    // Focus
    focus-inverse: constants.$terrestra48,
    // Miscenllaneous
    interactive: constants.$terrestra55,
    highlight: constants.$terrestra30,
  ));
// override a component token
@use '@carbon/react/scss/components/button/tokens' as button-tokens with ($button-primary: constants.$terrestra48,
  $button-primary-hover: constants.$terrestra40,
  $button-primary-active: constants.$terrestra30,
  $button-disabled: gray,
);

@use '@carbon/react';
@use '@carbon/type';
@use './containers/containers';
@use './components/components';

// Include type reset
@include type.reset();

// Include default type styles, targets h1, h2, h3, etc
@include type.default-type();

// Include utility classes for type-related properties
@include type.type-classes();

body {
  background: react.$background;
  color: react.$text-primary;
}

.theme-section {
  background: react.$background;
  color: react.$text-primary;
}

.chart-grid-backdrop {
  fill: #161616 !important;
}

.cds--cc--gauge path.arc-background {
  fill: var(--cds-layer-02) !important;
}

// Override Gauge arc background
.cds--data-table-container {
  width: 100%;
}

// Make Gauge plots value bolder
.bold .cds--cc--chart-wrapper g.gauge-numbers text.gauge-value-number {
  font-weight: bold !important;
}

#root {
  @include theme.theme();
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// Align modal content with header title
.cds--modal-content {
  padding: 1rem !important;
}

.cds--tag--red {
  background-color: #a2191f !important;
  color: white !important;
}

.cds--tag--green {
  background-color: #0e6027 !important;
  color: white !important;
}

.cds--tag--gray {
  background-color: #525252 !important;
  color: white !important;
}
