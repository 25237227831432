@import '@carbon/themes/scss/themes';

.terrestra-popup {
  background-color: #333333;
  color: var(--cds-text-secondary, #525252);
  border-radius: 5px;
  min-width: 250px;
  width: 250px;

  table {
    width: 100%;
    background-color: #333333;
    color: var(--cds-text-secondary, #525252);
    th,
    td {
      padding: 1px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #161616;
    }
  }
}

.maplibregl-popup-content {
  background-color: #161616 !important;
}

.geofencePopup {
  background-color: #161616;
  color: var(--cds-text-secondary, #525252);
  padding: 5px;
  border-radius: 5px;
  min-width: 350px;
  width: 250px;

  // <uniquifier>: Use a unique and descriptive class name
  // <weight>: Use a value from 100 to 900

  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 600;

  .geoFencePopupContent {
    display: flex;
    flex-direction: column;
    font-size: small;
    align-items: flex-start;
  }
}

.maplibregl-popup-close-button {
  color: #fff !important;
}
