@use '@carbon/themes';

.link {
  color: themes.$link-primary;

  &:hover {
    color: themes.$link-primary-hover;
  }
}

.contained-list {
  width: 100%;
  --cds-background: transparent;
  // Setting the typography to label-02 is not applied. Therefore we set the value manually.
  --cds-heading-compact-01-font-weight: 400;
  --cds-layout-density-padding-inline-local: 0;
}

.cds--contained-list--on-page + .cds--contained-list--on-page {
  margin-block-start: 0;
}

.contained-list-text {
  gap: 8px;
}
