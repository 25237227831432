.login-form {
  min-height: 60vh;
  min-width: 250px;
  max-width: 250px;
}

.login-button {
  width: 70%;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.bottom-right {
  bottom: 0;
  margin: 23px 27.74px;
  position: absolute;
  right: 0;
}

.color {
  // Get filter value from: https://codepen.io/sosuke/pen/Pjoqqp
  filter: invert(66%) sepia(96%) saturate(7419%) hue-rotate(10deg)
    brightness(104%) contrast(96%);
}

.version {
  color: rgba(white, 0.15);
}

.text-input {
  width: 250px;
}
