$animation-duration: 4s;

.full {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
}

.animation-container {
  height: 85px;
  position: relative;
  width: 85px;
}

.animation-rect {
  transform-origin: 50% 50%;
}

#text0,
#text1,
#text2,
#text3,
#text4 {
  animation: spin $animation-duration ease-in-out infinite forwards;
  transform: rotate(0deg);
}

#text1 {
  animation-delay: 1 * $animation-duration / 5;
}

#text2 {
  animation-delay: 2 * $animation-duration / 5;
}

#text3 {
  animation-delay: 3 * $animation-duration / 5;
}

#text4 {
  animation-delay: 4 * $animation-duration / 5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  3% {
    transform: rotate(90deg);
  }

  13% {
    transform: rotate(90deg);
  }

  20% {
    transform: rotate(0deg);
  }
}

// Opacity
#svg0,
#svg1,
#svg2,
#svg3,
#svg4 {
  animation: fade $animation-duration ease-in-out infinite forwards;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

#svg0 {
  animation-delay: 0;
}

#svg1 {
  animation-delay: 1 * $animation-duration / 5;
}

#svg2 {
  animation-delay: 2 * $animation-duration / 5;
}

#svg3 {
  animation-delay: 3 * $animation-duration / 5;
}

#svg4 {
  animation-delay: 4 * $animation-duration / 5;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  3% {
    opacity: 1;
  }

  13% {
    opacity: 1;
  }

  15% {
    opacity: 0;
  }
}
