@use '@carbon/colors';

$border-color: colors.$gray-80;

.sub-navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $border-color;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $border-color;
  padding: 0;
}

.sub-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px colors.$gray-70 solid;
  border-top: 1px colors.$gray-70 solid;
  padding: 10px 30px;
}

.page-content {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  background-color: colors.$gray-100;
  margin-block-start: 0 !important;
  flex: 1 1 auto;
}

// Icons fillers
svg.success {
  fill: colors.$green-40;
}

svg.error {
  fill: colors.$red-50;
}

svg.warning {
  fill: colors.$yellow-30;
}
